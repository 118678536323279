<template>
  <div class="flex items-center justify-center min-h-screen">
    <div class="is-size-4">404</div>
  </div>
</template>

<script>
export default {
  title: () => {
    return `Not Found - ${process.env.VUE_APP_TITLE}`;
  }
};
</script>
